<template>
  <div>
    <h3 class="page-header">Languages</h3>
    <b-form-row class="my-4 justify-content-between">
      <b-col lg="7" sm="7" cols="12">
        <div class="floating-input-field">
          <b-form-input
            id="form-input-search-languages"
            class="mb-2 mb-sm-1"
            v-model="filter"
            @input="currentPage = 1"
            placeholder=" "
            type="text"
          ></b-form-input>
          <label for="form-input-search-languages">Search languages <BIconSearch class="input-search-icon" /></label>
        </div>
      </b-col>
      <b-col lg="5" sm="5" cols="12" class="mb-2 mb-sm-1 text-right">
        <b-button type="button" id="add-language-btn" v-activeBlur variant="primary" @click="navigate('add-language')"
          >Add language <BIconPlus scale="1.5"
        /></b-button>
      </b-col>
    </b-form-row>
    <!-- Language table element -->
    <b-table
      :items="languages"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @context-changed="setTableCache($event, 'languages')"
      @row-dblclicked="onLanguageRowDblclicked"
      show-empty
      @filtered="onFiltered"
      hover
      class="table-transition"
      id="languages-table"
      primary-key="id"
      :tbody-transition-props="transProps"
      :busy="languagesLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(isDefault)="row">
        {{ row.value ? 'Yes' : 'No' }}
      </template>
      <template #cell(fallBackLanguageCode)="row">
        {{ row.value ? row.value : 'None' }}
      </template>
      <template #cell(actions)="row">
        <b-dropdown class="action-dropdown" no-caret variant="outline-secondary" right>
          <template #button-content>
            Actions
            <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
          </template>
          <b-dropdown-item @click="navigate(`edit-language/${row.item.id}`)">Edit</b-dropdown-item>
          <b-dropdown-item v-if="row.item.cultureCode.toLowerCase() !== 'en-us'" href="javascript:void(0)" @click="deleteLanguageConfirmDailog(row.item)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>
    <!-- Table options-->
    <b-form-row>
      <b-col sm="6" md="6" lg="3" cols="12" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="5"
          label-cols-md="6"
          label-cols-lg="4"
          label-cols-xl="3"
          label-cols="3"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="d-none d-lg-block"></b-col>
      <b-col sm="6" md="6" lg="3" class="my-1">
        <b-pagination
          v-if="!languagesLoading"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-form-row>
    <ConfirmationModal
      :showModal="deleteLanguageConfirmModal"
      :title="`CONFIRM`"
      :message="selectedLanguage ? (selectedLanguage.isDefault ? `DELETE_DEFAULT_LANGUAGE` : `DELETE_LANGUAGE`) : ''"
      @onConfirm="deleteLanguage"
      @closeConfirmModal="deleteLanguageConfirmModal = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconPlus, BIconSearch, BIconChevronDown } from 'bootstrap-vue'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
import { useTableOptionsCache } from '@/composables/useTableOptionsCache'
import { useAxios } from '@/composables/useAxios'
export default {
  name: 'Languages',
  setup() {
    const { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache } = useTableOptionsCache('languages')
    const { isLoading: languagesLoading, response: languagesResponse, error: languagesError, callApi: languagesApi } = useAxios()
    const { response: deleteLanguageResponse, error: deleteLanguageError, callApi: deleteLanguageApi } = useAxios()
    return {
      languagesLoading,
      languagesResponse,
      languagesError,
      languagesApi,
      deleteLanguageResponse,
      deleteLanguageError,
      deleteLanguageApi,
      filter,
      perPage,
      currentPage,
      sortBy,
      sortDesc,
      getTableCache,
      setTableCache,
    }
  },
  data() {
    return {
      transProps: {
        // Transition name
        name: 'flip-list',
      },
      deleteLanguageConfirmModal: false,
      selectedLanguage: null,
      fields: [
        {
          key: 'languageName',
          label: 'Language',
          sortable: true,
        },
        {
          key: 'cultureCode',
          label: 'ISO code',
          sortable: true,
        },
        { key: 'isDefault', label: 'Default language', sortable: true },
        { key: 'fallBackLanguageCode', label: 'Fall back language', sortable: true },
        { key: 'actions', label: '' },
      ],
      totalRows: 1,
      pageOptions: [5, 10, 15, 25],
      sortDirection: 'asc',
      filterOn: ['languageName', 'cultureCode', 'fallBackLanguageCode'],
    }
  },
  components: {
    BIconPlus,
    BIconSearch,
    BIconChevronDown,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue'),
  },
  computed: {
    ...mapState({
      languages: (state) => state.languages.languages,
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  mounted() {
    this.getLanguages()
  },
  methods: {
    navigate(url) {
      this.$router.push(url)
    },
    onLanguageRowDblclicked(item) {
      this.navigate(`edit-language/${item.id}`)
    },
    async getLanguages() {
      if (this.selectedTenant.length) {
        await this.languagesApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getLanguages) })
        if (this.languagesResponse) {
          this.$store.commit('languages/setLanguages', this.languagesResponse)
        }
        if (this.languagesError) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'LANGUAGES_LIST_ERROR',
            type: 'danger',
          })
          this.totalRows = 0
        }
      }
    },
    deleteLanguageConfirmDailog(item) {
      this.selectedLanguage = item
      this.deleteLanguageConfirmModal = true
    },
    async deleteLanguage() {
      await this.deleteLanguageApi({ method: 'post', url: `${ServiceUrls.deleteLanguage}/${this.selectedLanguage.id}` })
      if (this.deleteLanguageResponse) {
        this.deleteLanguageConfirmModal = false
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'DELETE_LANGUAGE_SUCCESS',
          type: 'success',
        })
        this.getLanguages()
      }
      if (this.deleteLanguageError) {
        this.deleteLanguageConfirmModal = false
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'FAILED',
          type: 'danger',
        })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.getLanguages()
    },
    languages() {
      this.totalRows = this.languages.length
      this.getTableCache('languages')
    },
  },
}
</script>
<style lang="scss" scoped></style>
