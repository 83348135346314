import { ref } from 'vue'
const TableOptions = {
  filter: null,
  perPage: 10,
  currentPage: 1,
  sortBy: '',
  sortDesc: false
}
const CacheTables = {
  cities: { ...TableOptions, sortBy: 'cityName' },
  widgets: { ...TableOptions, sortBy: 'widgetIndex' },
  linkAccounts: { ...TableOptions, sortBy: 'accountName' },
  security: { ...TableOptions, sortBy: 'accountName' },
  languages: { ...TableOptions, sortBy: 'id' }
}
export function useTableOptionsCache(tableId) {
  const filter = ref(null)
  const perPage = ref(10)
  const currentPage = ref(1)
  const sortBy = ref(CacheTables[tableId].sortBy)
  const sortDesc = ref(false)
  const getTableCache = (tableId) => {
    filter.value = CacheTables[tableId].filter
    perPage.value = CacheTables[tableId].perPage
    currentPage.value = CacheTables[tableId].currentPage
    sortBy.value = CacheTables[tableId].sortBy
    sortDesc.value = CacheTables[tableId].sortDesc
  }
  const setTableCache = (options, tableId) => {
    CacheTables[tableId] = options
  }
  return { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache }
}
